<template>
  <div>
    <b-modal
      id="cancel-appointment"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('CancelAppointment')"
      hide-footer
    >
      <p>{{ $t('ConfirmationAppointment') }}</p>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Confirm") }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['appointmentIdClient'],
  data() {
    return {
    }
  },
  methods: {
    onSubmit() {
      this.$emit('cancelApp', this.appointmentIdClient)
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
